import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { createMarkup } from '/Foundation/React/code/Utilities/HelperUtilities';
import { identity, signupSources, identitySources } from '/Foundation/CDP/code/Utilities/cdpHelper';
import { cdpSubscribe } from '/Foundation/Common/code/Helpers/newsletterHelper';

function Newsletter({ Newsletter, HideNewsletterSignUp }) {
  let input;
  const [sitecoreSendIsLoading, setSitecoreSendIsLoading] = useState(false);
  const [sitecoreSendIsError, setSitecoreSendIsError] = useState(false);
  const [sitecoreSendIsSuccess, setSitecoreSendIsSuccess] = useState(false);
  return (
    !HideNewsletterSignUp && (
      <div className="newsletter-component">
        <div className="layout__content pt-0">
          <div className="thule-container">
            <div className="row py-14">
              <div className="col-lg-12">
                <div className="text-section text-center">
                  <h2
                    className="paragraph--s top-text mb-4"
                    dangerouslySetInnerHTML={createMarkup(Newsletter?.Text)}
                  ></h2>
                  <label
                    htmlFor="newsletter-input-field"
                    className="description heading3 mb-9"
                    dangerouslySetInnerHTML={createMarkup(Newsletter?.Description)}
                  ></label>
                </div>
              </div>
              <div className="col-lg-12">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    identity({
                      email: input.value,
                      checkTrackingEnabled: false,
                      source: identitySources.footerNewsletterSignup,
                    });

                    setSitecoreSendIsError(false);
                    setSitecoreSendIsLoading(true);

                    cdpSubscribe(signupSources.footer);

                    setSitecoreSendIsLoading(false);
                    setSitecoreSendIsSuccess(true);

                    input.value = '';
                  }}
                >
                  <div className="d-flex">
                    <input
                      id="newsletter-input-field"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      title={Newsletter?.InvalidEmail}
                      placeholder={Newsletter?.EmailPlaceholder}
                      className="form-control newsletter-email-input paragraph--s"
                      required
                      ref={(node) => {
                        input = node;
                      }}
                    />
                    <button type="submit" className="btn thule-btn-default-bg-dark signup-btn p-0">
                      <i className="le-icon-arrow rot-180"></i>
                      <span className="sr-only">Sign up to newsletter</span>
                    </button>
                  </div>
                  <div className="mt-5">
                    <label htmlFor="newsletter-privacy-checkbox" className="form-check-label">
                      <div className="form-check">
                        <input
                          id="newsletter-privacy-checkbox"
                          className="form-check-input"
                          type="checkbox"
                          title={Newsletter?.InvalidPrivacyPolicy}
                          required
                        />
                        <div
                          className="paragraph--s ms-4 pt-1"
                          dangerouslySetInnerHTML={createMarkup(Newsletter?.PrivacyPolicy)}
                        ></div>
                      </div>
                    </label>
                  </div>
                </form>
                {sitecoreSendIsLoading && <p>{Newsletter?.Loading}</p>}
                {sitecoreSendIsError && (
                  <div className="error message paragraph--s mt-4">{Newsletter?.ValidationError}</div>
                )}
                {sitecoreSendIsSuccess && (
                  <div className="success message paragraph--s mt-4">{Newsletter?.SuccessMessage}</div>
                )}
              </div>
            </div>
            <hr className="my-0" />
          </div>
        </div>
      </div>
    )
  );
}

const root = createRoot(document.getElementById('REACT_newsletter'));
root.render(<Newsletter {...window._THULEDATA._Footer} />);
